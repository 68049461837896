import { UserOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Divider, Input, Pagination, Popconfirm, Spin } from "antd";
import { useCallback, useContext, useState } from "react";
import Chat from "./Chat";

import styles from "./index.module.css";
import { ChatsContext } from "context/chatsContext";

export enum STATUSES {
  WAIT = "WAIT",
  IN_PROGRESS = "IN_PROGRESS",
  FINISHED = "FINISHED",
}

const MainMessagesContent = () => {
  const {
    loading,
    chats,
    paginationChats,
    setInputSearchFieldByUserData,
    setStatusesOfChat,
    statusesOfChats,
    refreshUsersByStatus,
  } = useContext(ChatsContext);
  const [currentChat, setCurrentChat] = useState(null);

  const changeStatus = useCallback(
    (status: STATUSES) => {
      setCurrentChat({});
      paginationChats.current = { skip: 0, take: 10, total: 0 };
      setStatusesOfChat([status]);
    },
    [paginationChats, setStatusesOfChat],
  );

  const onChangePagination = (page) => {
    paginationChats.current = { ...paginationChats.current, skip: page * 10 - 10 };
    refreshUsersByStatus();
  };
  return (
    <div className={styles.container}>
      <Divider orientation="left" className={styles.divider}>
        Messages
      </Divider>
      <div className={styles.buttonStatusWrapper}>
        <h3>User by statuses</h3>
        <div className={styles.buttonsWithSearch}>
          <Button
            type="primary"
            style={statusesOfChats[0] === STATUSES.WAIT ? { boxShadow: "0 0 10px #ffcc4a" } : {}}
            onClick={() => changeStatus(STATUSES.WAIT)}
          >
            WAIT
          </Button>
          <Button
            type="primary"
            style={
              statusesOfChats[0] === STATUSES.IN_PROGRESS
                ? { backgroundColor: "yellowgreen", boxShadow: "0 0 10px yellowgreen" }
                : { backgroundColor: "yellowgreen" }
            }
            onClick={() => changeStatus(STATUSES.IN_PROGRESS)}
          >
            IN PROGRESS
          </Button>
          <Button
            type="primary"
            style={
              statusesOfChats[0] === STATUSES.FINISHED
                ? { backgroundColor: "#2986cc", boxShadow: "0 0 10px #2986cc" }
                : { backgroundColor: "#2986cc" }
            }
            onClick={() => changeStatus(STATUSES.FINISHED)}
          >
            FINISHED
          </Button>
          <Input
            placeholder="search by user name/id"
            onChange={(e) => {
              setInputSearchFieldByUserData(e.target.value);
            }}
          />
        </div>
      </div>
      {loading ? (
        <Spin style={{ position: "absolute", top: "50%", right: "50%", transform: "translate(50%, -50%)" }}></Spin>
      ) : (
        <div className={styles.messenger}>
          <div className={styles.connections}>
            {chats?.chats.map((chat) => (
              <Popconfirm
                key={chat.id}
                title="Start The Chat?"
                onConfirm={() => {
                  localStorage.setItem("prevChatIdAdmin", chat.id);
                  setCurrentChat(chat);
                }}
                disabled={statusesOfChats[0] === STATUSES.IN_PROGRESS}
              >
                <Button
                  className={
                    currentChat?.id === chat.id ? `${styles.connectButton} ${styles.acrive}` : styles.connectButton
                  }
                  onClick={() => {
                    if (statusesOfChats[0] != STATUSES.IN_PROGRESS) return;
                    localStorage.setItem("prevChatIdAdmin", chat.id);
                    setCurrentChat(chat);
                  }}
                >
                  {chat.client?.avatar ? (
                    <img
                      className={styles.picture}
                      src={`https://c2c-api.goldapp.ru/public/avatars/${chat.client.avatar}`}
                      width={46}
                      height={46}
                    />
                  ) : (
                    <Avatar size={46} icon={<UserOutlined />} />
                  )}
                  <div className={styles.textWrapper}>
                    <div className={styles.textTitle}>
                      {`${chat.client?.firstName} ${chat.client?.lastName}`}
                      {chat.partnerOnline ? <Badge status="success" style={{ marginLeft: "8px" }} /> : null}
                    </div>
                    <div className={styles.supportText}>{`${
                      chat.lastMessage?.length ? chat.lastMessage[0]?.content.slice(0, 20) : ""
                    }...`}</div>
                  </div>
                  {chat.unreadMessages ? (
                    <div className={styles.pictureWrapper}>
                      <Badge status="warning" />
                    </div>
                  ) : null}
                </Button>
              </Popconfirm>
            ))}
          </div>
          <Chat
            id={currentChat?.id}
            currentStatusChat={statusesOfChats[0]}
            closeChat={() => {
              setCurrentChat({});
            }}
          />
        </div>
      )}
      {!loading && (
        <Pagination
          total={paginationChats.current.total}
          current={paginationChats.current.skip / 10 + 1}
          onChange={onChangePagination}
          className={styles.paginationComponent}
        />
      )}
    </div>
  );
};

export default MainMessagesContent;
