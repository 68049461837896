import { Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";

type Props = {
  language: {
    id: string;
    code: string;
    name: string;
  };
};

const LanguageForm = ({ language }: Props) => {
  return (
    <>
      <Form.Item
        name={`name${language.code}`}
        label={`Name (${language.code})`}
        rules={[
          {
            required: true,
            message: "name is required",
          },
          () => ({
            validator(_, values) {
              if (values[0] !== " ") {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Space as a first letter is not allowed"));
            },
          }),
        ]}
      >
        <Input type="text" placeholder="name" />
      </Form.Item>
      <Form.Item
        name={`description${language.code}`}
        label={`Description (${language.code})`}
        rules={[
          {
            required: true,
            message: "description is required",
          },
          () => ({
            validator(_, values) {
              if (values[0] !== " ") {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Space as a first letter is not allowed"));
            },
          }),
        ]}
      >
        <TextArea rows={2} />
      </Form.Item>
    </>
  );
};

export default LanguageForm;
