import { Button, Dropdown, MenuProps, Popconfirm } from "antd";
import { assignRole, activateUser, approveUser, deleteUser } from "helpers/backend_helper";
import { useRequest } from "ahooks";
import { useContext, useState } from "react";
import { NotificationContext } from "context/notificationContext";
import UserModal from "./Modal";
import { UsersContext, type User } from "context/usersContext";
import { AdminContext } from "context/adminContext";

import styles from "./index.module.css";

type Props = {
  user: User;
};

const ActionButtons = ({ user }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { localCountry } = useContext(AdminContext);
  const { getUsersList } = useContext(UsersContext);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { loading, run: approve } = useRequest(approveUser, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "user updated");
    },
  });
  const { loading: deleteUserLoading, run: deleteUserHandler } = useRequest(deleteUser, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "user was deleted");
      getUsersList(localCountry.iso);
    },
  });
  const { loading: activateUserLoading, run: activateUserHandler } = useRequest(activateUser, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "user was activated");
      getUsersList(localCountry.iso);
    },
  });

  const { run: assignRoleHandler } = useRequest(assignRole, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "user was assigned a new role");
      getUsersList(localCountry.iso);
    },
  });

  if (!user) return null;

  const allowArbitrAssign = user.active && !user?.userRoles?.find((el) => el.role === "ARBITR");
  const allowSupportAssign = user.active && !user?.userRoles?.find((el) => el.role === "SUPPORT");

  const itemsRaw: MenuProps["items"] = [
    allowArbitrAssign
      ? {
          key: 1,
          label: (
            <Button type="text" onClick={() => assignRoleHandler(user.id, "ARBITR", localCountry.iso)}>
              appoint as arbitr
            </Button>
          ),
        }
      : null,
    !user.userRoles.find((v) => v.role === "SUPERADMIN")
      ? {
          key: 2,
          label: (
            <Button type="text" onClick={() => assignRoleHandler(user.id, "SUPERADMIN", localCountry.iso)}>
              appoint as SA
            </Button>
          ),
        }
      : null,
    !user.userRoles.find((v) => v.role === "SUPERSUPPORT")
      ? {
          key: 3,
          label: (
            <Button type="text" onClick={() => assignRoleHandler(user.id, "SUPPORTSUPPORT", localCountry.iso)}>
              appoint as SS
            </Button>
          ),
        }
      : null,
    allowSupportAssign
      ? {
          key: 4,
          label: (
            <Button type="text" onClick={() => assignRoleHandler(user.id, "SUPPORT", localCountry.iso)}>
              appoint as support
            </Button>
          ),
        }
      : null,
  ];
  const items = itemsRaw.filter((v) => v);
  console.log(items);
  return (
    <div className={styles.buttons}>
      <Button
        loading={loading}
        size="large"
        className={styles.button}
        ghost
        danger
        style={{ fontWeight: 400 }}
        onClick={() => setIsModalOpen(true)}
      >
        edit
      </Button>
      <UserModal setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} user={user} />
      <Popconfirm
        title={`Delete ${user.firstName} ${user.lastName}`}
        description="Are you sure to delete this user?"
        onConfirm={() => deleteUserHandler(user.id, localCountry.iso)}
        okText="Yes"
        cancelText="No"
      >
        <Button
          loading={deleteUserLoading}
          className={styles.button}
          type="primary"
          danger
          style={{ fontWeight: 400 }}
          size="large"
        >
          delete
        </Button>
      </Popconfirm>
      {!user.active ? (
        <Popconfirm
          title={`Activate ${user.firstName} ${user.lastName}`}
          description="Are you sure to activate this user?"
          onConfirm={() => activateUserHandler(user.id, localCountry.iso)}
          okText="Yes"
          cancelText="No"
        >
          <Button
            loading={activateUserLoading}
            className={styles.activateButton}
            type="primary"
            style={{ fontWeight: 400 }}
            size="large"
          >
            activate
          </Button>
        </Popconfirm>
      ) : null}
      {user.userRoles.find((v) => v.role === "SUPERADMIN") && items.length ? (
        <Dropdown menu={{ items }} arrow>
          <Button type="primary" color="geekblue" className={styles.button} style={{ fontWeight: 400 }} size="large">
            assigne
          </Button>
        </Dropdown>
      ) : null}

      {user.userRoles[0].approved ? null : (
        <Button
          size="large"
          loading={loading}
          className={styles.button}
          onClick={() => approve(user.id, user.userRoles[0]?.role, localCountry.iso)}
          type="primary"
          style={{ fontWeight: 400 }}
        >
          confirm admin
        </Button>
      )}
      {/* {user.userRoles[0].approved && allowArbitrAssign && (
        <Button
          size="large"
          loading={assignRoleLoading}
          className={styles.button}
          onClick={() => assignRoleHandler(user.id, "ARBITR", localCountry.iso)}
          type="primary"
          style={{ fontWeight: 400 }}
        >
          appoint as arbitr
        </Button>
      )}
      {user.userRoles[0].approved && !user.userRoles.find((v) => v.role === "SUPERADMIN") && (
        <Button
          size="large"
          loading={assignRoleLoading}
          className={styles.button}
          onClick={() => assignRoleHandler(user.id, "SUPERADMIN", localCountry.iso)}
          type="primary"
          style={{ fontWeight: 400 }}
        >
          appoint as SA
        </Button>
      )}
      {user.userRoles[0].approved && allowSupportAssign && (
        <Button
          size="large"
          loading={assignRoleLoading}
          className={styles.button}
          onClick={() => assignRoleHandler(user.id, "SUPPORT", localCountry.iso)}
          type="primary"
          style={{ fontWeight: 400 }}
        >
          appoint as support
        </Button>
      )} */}
    </div>
  );
};

export default ActionButtons;
