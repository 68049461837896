import { useRequest } from "ahooks";
import { AdminContext } from "context/adminContext";
import { NotificationContext } from "context/notificationContext";
import { SocketContext } from "context/socketContext";
import { getArbitrChat } from "helpers/backend_helper";
import { useCallback, useContext, useEffect, useRef } from "react";

export const useArbitrSocket = (chatId: string) => {
  const { socket, chatIdRef } = useContext(SocketContext);
  const { accountData, localCountry, setChats, chats } = useContext(AdminContext);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const countryIsoCode = localCountry?.iso.toUpperCase();
  const hasMore = useRef(true);

  chatIdRef.current = chatId;

  const { loading: historyLoading, run: getDebateHistory } = useRequest(
    (cursor: number) => getArbitrChat(cursor, localCountry.iso.toUpperCase(), chatId),
    {
      ready: !!chatId,
      onError: (error: any) => {
        if (error?.response.data.message) {
          openNotificationWithIcon("error", error.response.data.message);
        } else {
          openNotificationWithIcon("error", error.message);
        }
      },
      onSuccess: (data) => {
        if (data?.messages.length) {
          if (hasMore.current) {
            setChats((prev) => {
              return {
                ...prev,
                debateChat: {
                  cursor: data.cursor,
                  messages: prev.debateChat.messages.concat(data.messages),
                },
                selected: {
                  chatId,
                  type: "debateChat",
                },
              };
            });
          } else {
            hasMore.current = true;
            setChats((prev) => {
              return {
                ...prev,
                debateChat: data,
                selected: {
                  chatId,
                  type: "debateChat",
                },
              };
            });
          }
        } else {
          hasMore.current = false;
        }
      },
    },
  );

  const joinChatArbitr = useCallback(
    (chatId: string) => {
      socket.emit("joinChatArbitr", { chatId, countryIsoCode });
      socket.emit("joinClientMessagesSubscribe", {
        countryIsoCode,
        chatId,
      });
    },
    [socket, countryIsoCode],
  );

  const sendMessageArbitr = (content: string, chatId: string) => {
    return new Promise((res) => {
      socket.emit("sendMessage", { chatId, content, countryIsoCode, createByAdmin: true }, (response) => {
        res(response);
        setChats((prev) => {
          const debateChat = prev.debateChat;
          debateChat.messages.unshift({
            grouping_key: +debateChat.messages[0].grouping_key + 1,
            messages: [
              {
                createdAt: new Date().toISOString(),
                chatId,
                content,
                admin: accountData.id,
                id: debateChat.messages[0].messages[0].id ? debateChat.messages[0].messages[0].id + 1 : 1,
                contentType: "text",
              },
            ],
          });

          return { ...prev, debateChat };
        });
      });
    });
  };

  useEffect(() => {
    return () => {
      setChats((prev) => ({ ...prev, debateChat: { cursor: 0, messages: [] } }));
    };
  }, [setChats]);

  return {
    joinChatArbitr,
    sendMessageArbitr,
    messages: chats.debateChat,
    historyLoading,
    hasMore,
    cursor: chats.debateChat.cursor,
    getDebateHistory,
  };
};
