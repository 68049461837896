import { useRequest } from "ahooks";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { getAccount, getCountry, getSupportChat } from "helpers/backend_helper";
import { User } from "./usersContext";
import { Country } from "pages/Main/Components/AdminPanel/Countries";
import { NotificationContext } from "./notificationContext";

export type ContextProps = {
  isAdmin: boolean;
  loading: boolean;
  setIsAdmin: React.Dispatch<React.SetStateAction<boolean>>;
  isAuthorized: boolean;
  setIsAuthorized: React.Dispatch<React.SetStateAction<boolean>>;
  accountData: User;
  refreshAccountData: (country: string) => void;
  localCountry: Country;
  setLocalCountry: React.Dispatch<React.SetStateAction<Country>>;
  countries: Country[];
  refreshCountries: () => void;
  chats: Chats;
  setChats: React.Dispatch<React.SetStateAction<Chats>>;
  getChatHistory: (id: string, cursor: number) => void;
  getHistoryLoading: boolean;
  hasMore: React.MutableRefObject<boolean>;
};

export type FileRenderType = { content: string; link: string };

export enum ChatType {
  RENT = "RENT",
  PERSONAL = "PERSONAL",
  SUPPORT = "SUPPORT",
}

export type Message = {
  grouping_key?: string | number;
  messages?: any;
};

export type Chat = { cursor: number; messages: Message[] };

export type Chats = {
  supportChat: Chat;
  debateChat: Chat;
  selected: {
    type: "supportChat" | "debateChat";
    chatId: string;
  };
};

export const AdminContext = createContext({} as ContextProps);

const initialMessages = { cursor: 0, messages: [] };

export const AdminProvider = ({ children }: { children: JSX.Element }) => {
  const [isAdmin, setIsAdmin] = useState(true);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const [isAuthorized, setIsAuthorized] = useState(
    !!localStorage.getItem("accessToken") || !!sessionStorage.getItem("accessToken"),
  );
  const { data: countries, refresh: refreshCountries } = useRequest(getCountry, { ready: isAuthorized });
  const [localCountry, setLocalCountry] = useState<Country>(JSON.parse(localStorage.getItem("country")));
  const [chats, setChats] = useState<Chats>({ supportChat: initialMessages, debateChat: initialMessages } as Chats);
  const hasMore = useRef(true);
  const newChat = useRef(true);
  const prevId = useRef("");
  const { loading: getHistoryLoading, run: getChatHistory } = useRequest(
    (id: string, cursor: number) => {
      if (!prevId) {
        newChat.current = true;
        prevId.current = id;
      } else {
        newChat.current = prevId.current !== id;
        prevId.current = id;
      }
      return getSupportChat(cursor, localCountry.iso.toUpperCase(), id);
    },
    {
      manual: true,
      refreshDeps: [isAuthorized],
      onError: (error: any) => {
        if (error?.response.data.message) {
          openNotificationWithIcon("error", error.response.data.message);
        } else {
          openNotificationWithIcon("error", error.message);
        }
      },
      onSuccess: (data, params) => {
        if (!newChat.current) {
          if (data?.message.length) {
            if (hasMore.current) {
              setChats((prev) => {
                return {
                  ...prev,
                  supportChat: { cursor: data.cursor, messages: prev.supportChat.messages.concat(data.message) },
                  selected: { chatId: params[0], type: "supportChat" },
                };
              });
            } else {
              if (data?.message.length < 10) {
                hasMore.current = false;
              }
              setChats((prev) => {
                return {
                  ...prev,
                  supportChat: { cursor: data.cursor, messages: data.message },
                  selected: { chatId: params[0], type: "supportChat" },
                };
              });
            }
          } else {
            hasMore.current = false;
          }
        } else if (data?.message.length) {
          hasMore.current = data.message.length < 10 ? false : true;
          setChats((prev) => {
            return {
              ...prev,
              supportChat: { cursor: data.cursor, messages: data.message },
              selected: { chatId: params[0], type: "supportChat" },
            };
          });
        } else {
          if (newChat.current) {
            setChats((prev) => {
              return {
                ...prev,
                supportChat: { cursor: 0, messages: [] },
                selected: { chatId: params[0], type: "supportChat" },
              };
            });
          }
          hasMore.current = false;
        }
        newChat.current = false;
      },
    },
  );

  const {
    loading,
    data: accountData,
    run: refreshAccountData,
  } = useRequest(getAccount, {
    ready: isAuthorized,
  });

  useEffect(() => {
    if (accountData?.id) {
      setIsAuthorized(true);
    }
  }, [accountData]);

  useEffect(() => {
    if (countries) {
      const local = localStorage.getItem("country");
      if (!local) {
        localStorage.setItem("country", JSON.stringify(countries[0]));
        setLocalCountry(countries[0]);
      } else {
        const country = countries.find((c) => c?.iso === localCountry?.iso);
        localStorage.setItem("country", JSON.stringify(country));
        setLocalCountry(country);
      }
    }
  }, [countries, localCountry]);

  return (
    <AdminContext.Provider
      value={{
        isAdmin,
        setIsAdmin,
        isAuthorized,
        setIsAuthorized,
        accountData,
        loading,
        refreshAccountData,
        localCountry,
        setLocalCountry,
        countries,
        refreshCountries,
        chats,
        setChats,
        getChatHistory,
        hasMore,
        getHistoryLoading,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};
