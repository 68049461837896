import { useDebounce, useRequest } from "ahooks";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { getChats } from "helpers/backend_helper";
import { NotificationContext } from "./notificationContext";
import { AdminContext } from "./adminContext";
import { SocketContext } from "./socketContext";
import { STATUSES } from "pages/Main/Components/AdminPanel/Chats";
import { UploadFile } from "antd";
import { Pagination } from "pages/Main/Components/AdminPanel/Options";
import { useChatLoadReady } from "hooks/useChatLoadReady";

export type Chat = {
  client: {
    avatar: string | null;
    cityId: number;
    description: string | null;
    email: string;
    firstName: string;
    id: string;
    lastName: string;
    patronymic: string | null;
  };
  clientId: string;
  createdAt: string;
  id: string;
  lastMessage: any[];
  status: string;
  support: string;
  unreadMessages: number;
  updatedAt: string;
  partnerOnline: boolean;
};

export type FileDataType = {
  filePath: string;
  originalName: string;
};

export type BunchFilesType = {
  files: FileDataType[];
  status: "DONE" | "LOADING" | "ERROR" | "READY";
};

export type Chats = {
  count: number;
  chats: Chat[];
};

export type ContextProps = {
  chats: Chats;
  loading: boolean;
  fileList: UploadFile[];
  bunchFiles: BunchFilesType;
  paginationChats: React.MutableRefObject<Pagination>;
  // setPaginationChats: React.Dispatch<React.SetStateAction<Pagination>>;
  resetInputFields: () => void;
  setChats: React.Dispatch<Chats>;
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  setBunchFiles: React.Dispatch<React.SetStateAction<BunchFilesType>>;
  debouncedSearch: string;
  refreshUsersByStatus: () => void;
  statusesOfChats: STATUSES[];
  setStatusesOfChat: React.Dispatch<React.SetStateAction<string[]>>;
  setInputSearchFieldByUserData: React.Dispatch<React.SetStateAction<string>>;
};

export const ChatsContext = createContext({} as ContextProps);

export const ChatsProvider = ({ children }: { children: JSX.Element }) => {
  const [chats, setChats] = useState<Chats | null>(null);
  const paginationChats = useRef<Pagination>({ skip: 0, take: 10, total: 0 });
  const { localCountry, isAuthorized, accountData } = useContext(AdminContext);
  const { subscribeToUserOnliness, online } = useContext(SocketContext);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [bunchFiles, setBunchFiles] = useState<BunchFilesType>({ files: [], status: "READY" });
  const [inputSearchFieldByUserData, setInputSearchFieldByUserData] = useState("");
  const debouncedSearch = useDebounce(inputSearchFieldByUserData, { wait: 500, trailing: true });
  const [statusesOfChats, setStatusesOfChat] = useState<STATUSES[]>([STATUSES.IN_PROGRESS]);
  const isReady = useChatLoadReady();

  const resetInputFields = () => {
    setBunchFiles({ files: [], status: "READY" });
    setFileList([]);
  };

  useEffect(() => {
    if (online.clientId && chats?.chats.length) {
      const chat = chats?.chats.find((ch) => ch.clientId === online.clientId);
      chat.partnerOnline = online.online;
      setChats((prev) => {
        const sliced = prev.chats.slice();
        return { ...prev, chats: sliced };
      });
    }
  }, [chats?.chats, online]);

  const { loading, refresh: refreshUsersByStatus } = useRequest(
    () =>
      getChats(localCountry?.iso, statusesOfChats, {
        pagination: paginationChats.current,
        userData: debouncedSearch,
        inProgress: statusesOfChats[0] === STATUSES.IN_PROGRESS,
      }),
    {
      ready:
        !!localCountry &&
        isAuthorized &&
        !!accountData?.userRoles.find((v) => v.role === "SUPERSUPPORT" || v.role === "SUPPORT") &&
        !!isReady &&
        !!statusesOfChats[0],
      refreshDeps: [JSON.stringify(localCountry), debouncedSearch, statusesOfChats],
      onError: (error: any) => {
        console.error(error);
        openNotificationWithIcon("error", error.response?.data?.message || error.message);
      },
      onSuccess: (data) => {
        if (data) {
          paginationChats.current = { ...paginationChats.current, total: data.count };
          data.chats?.forEach((chat) => {
            subscribeToUserOnliness(chat.clientId);
          });
          const accessableChats = data.chats.filter((chat) => {
            if (chat.status === STATUSES.IN_PROGRESS) return chat.support === accountData.id;
            return true;
          });
          const chatsData = {
            count: accessableChats.length,
            chats: accessableChats,
          };
          console.log(chatsData);
          chatsData.chats = chatsData.chats.map((el) => ({ ...el, online: false }));
          setChats(chatsData);
        }
      },
    },
  );

  return (
    <ChatsContext.Provider
      value={{
        chats,
        loading,
        fileList,
        bunchFiles,
        paginationChats,
        statusesOfChats,
        debouncedSearch,
        setChats,
        setFileList,
        setBunchFiles,
        resetInputFields,
        refreshUsersByStatus,
        setStatusesOfChat,
        setInputSearchFieldByUserData,
      }}
    >
      {children}
    </ChatsContext.Provider>
  );
};
