import { Spin, Upload, UploadFile, UploadProps } from "antd";
import { PropsWithChildren, useContext, useEffect } from "react";

import { PaperClipIcon } from "Common/Icons/PaperClip";
import { SpinnerIcon } from "Common/Icons/Spinner";

import styles from "./index.module.css";
import { ChatsContext } from "context/chatsContext";
import { AdminContext } from "context/adminContext";
import { API_ORIGIN } from "pages/Main/Components/AdminPanel/Debate/DetailsModal/utils";

type Props = {
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  fileList: UploadFile[];
  isArbitr: boolean;
  chatId: string;
};

const LINK_TMP_SUPPORT = "support-chats/admin/tmp-file";
const LINK_TMP_ARBITR = "chats/tmp-file";
export const UploadFilesComponent = (props: PropsWithChildren<Props>) => {
  const { children, isArbitr, chatId } = props;
  const { localCountry } = useContext(AdminContext);
  const { fileList, setFileList, setBunchFiles } = useContext(ChatsContext);
  useEffect(() => {
    if (!fileList.length) return;
    const isDone = fileList.every(
      (file) => file.status === "done" || file.status === "error" || file.status === "removed",
    );
    setBunchFiles((prev) => ({ ...prev, status: isDone ? "DONE" : "LOADING" }));
  }, [fileList, setBunchFiles]);

  let actionLink: string;
  if (isArbitr) {
    actionLink = `${API_ORIGIN}/${LINK_TMP_ARBITR}/${chatId}`;
  } else {
    actionLink = `${API_ORIGIN}/${LINK_TMP_SUPPORT}/${chatId}`;
  }

  const uploadPropsFile: UploadProps = {
    action: actionLink,
    headers: {
      authorization: "Bearer " + localStorage.getItem("accessToken"),
      "country-iso-code": localCountry.iso,
      "accept-language": "EN",
    },
    multiple: true,
    onChange: ({ fileList, file }) => {
      setFileList(fileList);
      if (file.status === "removed") {
        setBunchFiles((prev) => {
          prev.files = prev.files.filter((fileToUpload) => file.name !== fileToUpload.originalName);
          return { ...prev };
        });
        setFileList((prev) => prev.filter((v) => v.name !== file.name));
        return;
      }
      if (file?.response && file.status === "done") {
        setBunchFiles((prev) => {
          const existingFile = prev.files.find((v) => v.originalName === file.response.originalName);
          if (!existingFile) {
            prev.files.push(file.response);
            return { ...prev };
          }
          return prev;
        });
      }
    },
    iconRender: function (file) {
      if (file.status === "uploading") return <Spin indicator={<SpinnerIcon />} spinning size="small" />;
      return <PaperClipIcon />;
    },
    beforeUpload: () => {
      setBunchFiles((prev) => ({ ...prev, status: "LOADING" }));
      return true;
    },
    fileList: fileList,
  };

  return (
    <Upload {...uploadPropsFile} className={styles.uploadFiles}>
      {children}
    </Upload>
  );
};
