import { useContext } from "react";
import { AdminContext } from "context/adminContext";
import { TonContext } from "context/tonContext";
import Link from "antd/es/typography/Link";
import { Button, Divider, Popconfirm } from "antd";
import { useRequest } from "ahooks";
import { deleteWallet } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import Contract from "./Contract";

import styles from "./index.module.css";

const Wallet = () => {
  const { accountData, refreshAccountData, localCountry } = useContext(AdminContext);
  const { universalLink } = useContext(TonContext);
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { loading, run: walletDelete } = useRequest(deleteWallet, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "wallet was deleted");
      refreshAccountData(localCountry.iso);
    },
  });

  console.log(accountData?.userRoles);
  return (
    <div className={styles.container}>
      <Divider orientation="left" className={styles.divider}>
        Wallet
      </Divider>
      {!accountData?.userWallets.length ? (
        <Link className={styles.link} target="_blank" href={universalLink}>
          add wallet
        </Link>
      ) : (
        <div>
          Wallet address: {accountData?.userWallets[0].address}{" "}
          {accountData?.userWallets[0]?.address ? (
            <Popconfirm
              title={`Delete wallet`}
              description="Are you sure to delete wallet?"
              onConfirm={() => walletDelete(localCountry.iso)}
              okText="Yes"
              cancelText="No"
            >
              <Button loading={loading} danger type="primary">
                delete wallet
              </Button>
            </Popconfirm>
          ) : null}
        </div>
      )}
      {accountData?.userRoles.find((v) => v.role === "SUPERADMIN") && <Contract />}
    </div>
  );
};

export default Wallet;
