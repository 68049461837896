import { useContext } from "react";
import { Button, Form, Modal, TreeSelect } from "antd";
import { useRequest } from "ahooks";
import { createGroup } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import { TreeData } from "../../Categories";
import { AdminContext } from "context/adminContext";
import LanguageForm from "pages/Main/Components/LanguageForm";

import styles from "./index.module.css";

type Props = {
  refresh: () => void;
  treeData: TreeData[];
  getCategoriesLoading: boolean;
  setIsCreateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCreateModalOpen: boolean;
};

const NewGroup = ({ refresh, treeData, getCategoriesLoading, isCreateModalOpen, setIsCreateModalOpen }: Props) => {
  const [form] = Form.useForm();
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { localCountry } = useContext(AdminContext);
  const languages = localCountry?.languages;
  const { loading: createLoading, run: createGrp } = useRequest(createGroup, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || error.message);
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "group was created");
      refresh();
      setIsCreateModalOpen(false);
    },
  });
  return (
    <Modal
      className={styles.modal}
      title="Create new group"
      open={isCreateModalOpen}
      footer={null}
      onCancel={() => setIsCreateModalOpen(false)}
    >
      <div className={styles.container}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            const categories = languages?.map((language) => {
              const nameKey = `name${language.code}`;
              const descriptionKey = `description${language.code}`;
              return { name: values[nameKey], description: values[descriptionKey], languageCode: language.code };
            });
            createGrp(categories, values?.tree || [], localCountry?.iso);
            form.resetFields();
          }}
          style={{ maxWidth: "600px" }}
        >
          {languages?.map((language) => <LanguageForm key={language?.id} language={language} />)}
          <Form.Item
            name="tree"
            label="Categories"
            rules={[
              {
                required: true,
                message: "Category is required",
              },
            ]}
          >
            <TreeSelect allowClear multiple loading={getCategoriesLoading} style={{ width: 300 }} treeData={treeData} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={createLoading}>
              Create
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default NewGroup;
