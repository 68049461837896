import { AdminContext } from "context/adminContext";
import { SocketContext } from "context/socketContext";
import { useContext, useEffect, useState } from "react";

export const useChatLoadReady = () => {
  const { isAuthorized, localCountry, accountData } = useContext(AdminContext);
  const { socket } = useContext(SocketContext);
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    if (isAuthorized && localCountry && socket && accountData?.userRoles.length) setIsReady(true);
  }, [isAuthorized, localCountry, socket, accountData]);

  return { isReady };
};
