import { useContext } from "react";
import { Button, Form, Input, Modal, Select } from "antd";
import { useRequest } from "ahooks";
import { createCountry } from "helpers/backend_helper";
import { NotificationContext } from "context/notificationContext";
import { isISO31661Alpha2, isISO31661Alpha3 } from "class-validator";
import type { Language } from "../../../Languages";

import styles from "./index.module.css";
import { AdminContext } from "context/adminContext";

type Props = {
  refresh: () => void;
  setIsCreateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isCreateModalOpen: boolean;
  languages: Language[];
};

const NewCountryModal = ({ refresh, isCreateModalOpen, setIsCreateModalOpen, languages }: Props) => {
  const { openNotificationWithIcon } = useContext(NotificationContext);
  const { refreshCountries } = useContext(AdminContext);

  const { loading: createLoading, run: create } = useRequest(createCountry, {
    manual: true,
    onError: (error: any) => {
      openNotificationWithIcon("error", error.response?.data?.message || "something went wrong");
    },
    onSuccess: () => {
      openNotificationWithIcon("success", "country was created");
      refresh();
      setIsCreateModalOpen(false);
      refreshCountries();
    },
  });

  const languageSelect = languages?.map((l) => ({ label: l.name, value: l.id }));

  return (
    <Modal
      className={styles.modal}
      title="Create new country"
      open={isCreateModalOpen}
      footer={null}
      onCancel={() => setIsCreateModalOpen(false)}
    >
      <div className={styles.container}>
        <Form
          className={styles.inputWrapper}
          onFinish={(values: any) => create(values.iso, values.name, values?.languages, values.default)}
        >
          <Form.Item
            name="iso"
            label="ISO"
            rules={[
              {
                required: true,
                message: "ISO is required",
              },
              () => ({
                validator(_, value) {
                  console.log(isISO31661Alpha3(value));
                  if (!value || isISO31661Alpha2(value) || isISO31661Alpha3(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("invalid ISO"));
                },
              }),
            ]}
          >
            <Input type="text" placeholder="ISO" name="iso" />
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "name is required",
              },
            ]}
          >
            <Input type="text" placeholder="name" name="languages" />
          </Form.Item>
          <Form.Item name="default" label="Default language">
            <Select placeholder="languages" options={languageSelect} />
          </Form.Item>
          <Form.Item
            name="languages"
            label="Languages"
            rules={[
              {
                required: true,
                message: "language is required",
              },
            ]}
          >
            <Select mode="multiple" placeholder="languages" options={languageSelect} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" loading={createLoading} htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default NewCountryModal;
