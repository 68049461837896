import { Table, Skeleton, Tag } from "antd";
import { useContext, useEffect, useMemo } from "react";
import ActionButtons from "../ActionButtons";
import { UsersContext } from "context/usersContext";
import { AdminContext } from "context/adminContext";

import styles from "./index.module.css";

type Roles = {
  role?: string;
  approved?: boolean;
};

type Role = {
  role: [Roles];
  active: boolean;
};

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: <div className={styles.renter}>First name</div>,
    dataIndex: "firstName",
    key: "firstName",
  },
  {
    title: <div className={styles.period}>Last name</div>,
    dataIndex: "lastName",
    key: "lastName",
  },
  {
    title: <div className={styles.price}>Email</div>,
    dataIndex: "email",
    key: "email",
  },
  {
    title: <div className={styles.price}>Status</div>,
    dataIndex: "status",
    key: "status",
  },
  {
    title: " ",
    dataIndex: "actions",
    key: "actions",
  },
];

const colorsOfRoles = {
  ADMIN: "blue",
  SUPPORT: "yellow",
  SUPERADMIN: "geekblue",
  SUPERSUPPORT: "cyan",
};
const UserStatus = ({ role, active }: Role) => {
  const approved = role ? role[0].approved : "";
  return (
    <div style={{ minWidth: "130px" }}>
      {role.map((v, i) => (
        <Tag key={i + colorsOfRoles[v.role]} color={colorsOfRoles[v.role]}>
          {v.role}
        </Tag>
      ))}
      {approved ? <Tag color="green">approved</Tag> : null}
      {!active ? <Tag color="red">deactivated</Tag> : null}
    </div>
  );
};

const AdminList = () => {
  const { loading, users, getUsersList } = useContext(UsersContext);
  const { localCountry } = useContext(AdminContext);

  const source = useMemo(
    () =>
      users?.map((el, i) => ({
        ...el,
        key: i,
        actions: <ActionButtons user={el} />,
        status: <UserStatus role={el?.userRoles} active={el?.active} />,
      })) || [],
    [users],
  );

  useEffect(() => {
    getUsersList(localCountry?.iso);
  }, []);

  return loading ? (
    <Skeleton />
  ) : (
    <Table loading={loading} dataSource={source} columns={columns} className={styles.table} pagination={false} />
  );
};

export default AdminList;
